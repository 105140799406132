@import "styles";

.confirmation {
  box-shadow: $shadow-lg;
  z-index: $layer-tooltip;

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    > * {
      margin-left: 16px;
    }
  }
}

.overlay {
  z-index: 1001;
}
