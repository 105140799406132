@import "styles";

.container {
  display: flex;
  border-radius: 16px;

  .shareButtonContainer {
    display: flex;
    max-height: 32px;
    border-radius: 28px;
    align-items: center;
    box-sizing: border-box;
    transition: background 400ms;

    .shareMenuButton {
      @include resetButton();

      color: var(--fallback-primary-btn-text, $white);
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 12px;
      border-radius: 28px;
      background: var(--fallback-primary-btn-bg, $purple-gradient);

      &:hover {
        background: var(--fallback-primary-btn-bg-hover, $purple-gradient);
      }

      svg {
        color: var(--fallback-primary-btn-text, $white);
        margin-right: 8px;
      }
    }
  }

  .copyLinkButton {
    width: 46px !important;
    border-radius: 16px !important;
    background: transparent !important;

    &.copyLinkButtonSmall {
      width: auto !important;
    }
  }
}
