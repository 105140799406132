@import "styles";

.wrapper {
  display: inline-flex;

  .label {
    font-size: 12px;
    line-height: 16px;

    &.large {
      font-size: 14px;
      line-height: 22px;
      padding-left: 8px;
    }
  }

  .input {
    opacity: 0;
    z-index: $layer-base;
    cursor: pointer;

    + label {
      position: relative;
      display: inline-block;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        display: inline-block;
        box-sizing: border-box;
        left: -19px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $fossil;
        background: $transparent;
      }

      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        left: -15px;
        top: 4px;
        border-radius: 50%;
        width: 8px;
        height: 8px;
      }
    }

    &:focus {
      + label::before {
        border: 1px solid $lilac;
        box-shadow: 0 0 1px 1px rgba($lilac, 0.5);
      }
    }

    &:hover {
      + label::before {
        background: $mist;
        border: 1px solid $fossil;
      }
    }

    &:disabled {
      cursor: not-allowed;

      + label {
        cursor: not-allowed;
        color: $fossil;

        &::before {
          background: $fossil;
          border: 1px solid $fossil;
        }
      }
    }

    &:checked {
      + label::before {
        border: 1px solid $grid-purple;
      }

      + label::after {
        background: $grid-purple;
      }

      &:disabled {
        + label::before {
          background: $white;
          border: 1px solid $fossil;
        }

        + label::after {
          background: $fossil;
        }
      }
    }

    &.large {
      + label::before {
        width: 20px;
        height: 20px;
        left: -19px;
      }

      + label::after {
        width: 10px;
        height: 10px;
        left: -14px;
        top: 5px;
      }
    }
  }
}
