@import "styles";

.embedCode {
  display: flex;
  flex-flow: column;
  gap: 16px;

  .code {
    textarea {
      resize: none;
      font-size: 14px;
      line-height: 18px;
      word-break: break-all;
    }
  }

  .link {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 16px;

    .input {
      flex-grow: 1;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .checkboxes {
    display: flex;
    flex-flow: row;
    flex-grow: 1;

    > * {
      margin: 8px 0;
      flex: 1;
    }

    > :last-of-type {
      margin-bottom: 0;
    }

    .checkbox {
      display: flex;
      gap: 2px;
      align-items: center;
    }
  }

  .radios {
    margin-bottom: 0;

    label {
      @include paragraph14SemiBold();

      display: block;
      margin-bottom: 8px;

      span {
        margin-right: 2px;
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    > :first-child {
      flex: 1;
    }

    button {
      margin-top: 8px;
    }
  }

  .actions, .link {
    button {
      justify-content: center;
      width: 96px;
    }
  }

  .copyEmbedButton {
    align-self: center;
  }

  .moreOptions {
    .toggle {
      @include resetButton();
      @include paragraph14SemiBold();

      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
}

.transparentBackground {
  margin-bottom: 16px;
}

.transparentBackgroundLabel {
  @include paragraph14SemiBold();

  margin-left: 8px;
  display: flex;
  align-items: center;
}

.transparentBackgroundButton {
  display: flex;
  align-items: center;
}

.transparentBackgroundSubtitle {
  @include paragraph14Regular();

  color: $ash;
}

.transparentBackgroundButtonContainer {
  display: flex;
}