@import "styles";

.picker {
  display: flex;
  margin-top: 8px;
  align-items: center;

  .select {
    flex-grow: 1;
  }

  .rights {
    margin-left: 24px;
    min-width: 50px;
    display: flex;
    justify-content: flex-end;
  }
}

.createAGroupHint {
  @include paragraph14Regular();

  margin-top: 8px;
}

.button {
  @include footerButtons();

  margin-top: 24px;
}

@keyframes come {
  0% { transform: translate(0%, 100%); }
  100% { transform: translate(0, 0); }
}
