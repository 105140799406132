@import "styles";

.domainAccess {
  .title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .subTitle {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: $ash;
  }

  .domainForm {
    display: flex;
    margin: 8px 0;

    > div:first-of-type {
      flex-grow: 1;
    }

    button {
      margin-left: 16px;
    }
  }

  .allowedDomains {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 94px;

    .pill {
      width: initial;
      flex-grow: 0;
      padding-left: 8px;
      color: $white;
      background-color: $squid-ink;

      button {
        margin-left: 8px;

        > * {
          color: rgba($white, .8);
        }
      }
    }
  }

  .domainLockIcon {
    margin-right: 4px;
  }
}
