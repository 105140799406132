@import "styles";

.selectInline {
  display: flex;
  align-items: center;
  font-family: $font-main;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: relative;

  &.inPortal {
    position: fixed;
    z-index: $layer-modal;
  }

  &.mobile {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background: rgb(0 0 0 / 10%);
    display: flex;
    align-items: flex-end;
    animation: fadeIn 200ms;

    @keyframes fadeIn {
      from {
        backdrop-filter: blur(0);
        background: rgb(0 0 0 / 0%);
      }
    }

    .container {
      background: $white;
      transform: translateY(100%);
      animation: come 300ms cubic-bezier(0, 1, .5, 1) forwards;

      @keyframes come {
        to { transform: translateY(0); }
      }

      &.open {
        margin: 0;
        border-radius: 16px 16px 0 0;

        button, a {
          @include paragraph16Regular();

          margin: 8px 0;

          .listIcon {
            margin-right: 16px;
          }
        }

        button.active, a.active {
          @include paragraph16SemiBold();
        }
      }
    }

    ul {
      padding: 8px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column;

    li {
      display: flex;
    }
  }

  .container {
    overflow: hidden;

    &.open {
      min-width: 120px;
      margin-left: -40px;
      box-shadow: $shadow-lg;
      border-radius: 8px;

      button, a {
        flex-grow: 1;
        display: flex;
        // if padding on top and on bottom changes, then we need to update the height of the button
        // in the component
        padding: 8px 16px;
        transition: background-color 240ms ease;

        &:hover {
          transition: background-color 240ms ease;
          background-color: $lavender;
        }

        .tagWrapper {
          position: relative;
          margin-left: 8px;
        }
      }

      li.hasDividerOnTop {
        border-top: 1px solid $wise-owl;
      }
    }
  }

  .iconWrapper {
    margin-left: 2px;
  }

  button, a {
    @include resetButton();
    @include paragraph14Regular();

    text-decoration: none;
    display: none;
    color: $squid-ink;
    background-color: $white;
    text-align: left;
    align-items: center;

    .listIcon {
      margin-right: 8px;
    }
  }

  button.active, a.active {
    @include paragraph14SemiBold();

    display: flex;
    color: $grid-purple;
  }
}

.mobileOutsideClickHandler {
  flex-grow: 1;
  position: relative;
}
