@import "styles";

.sharingHeader {
  display: flex;

  span {
    @include paragraph14SemiBold();

    color: $squid-ink;
  }

  .goBackButton {
    @include resetButton();

    display: flex;
    align-items: center;
    color: $grid-purple;
    gap: 4px;
  }
}
