@import "styles";

.toggleWrapper {
  margin: 16px;
  padding: 8px 0;
  box-sizing: border-box;
  color: $squid-ink;
  background: $bluebell;
  border-radius: 9px;

  .toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    padding: 0 8px;
  }

  .label {
    font-size: 12px;

    .icon {
      margin-left: 3px;
      margin-right: 10px;
    }
  }
}

.embedSection {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
  font-size: 12px;
  color: $squid-ink;
}

.separator {
  background-color: $wise-owl;
}

.peoplePicker {
  padding: 16px 16px 0;
}

.collaborators {
  padding: 0;
  display: flex;
  flex-flow: column;
  gap: 24px;

  &.bottomBorder {
    padding-bottom: 16px;
    border-bottom: 1px solid $wise-owl;
  }

  &.list {
    gap: 16px;
  }

  .moreCollaborators {
    @include paragraph14SemiBold();

    display: block;
    color: $grid-purple;
    margin-left: 32px;
    text-decoration: none;
    background: none;
    width: fit-content;

    .angleRight {
      margin-left: 2px;
    }
  }

  .collaborator {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;

    .avatar {
      margin-right: 8px;
      width: 24px;
    }

    .text {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 80px);

      .name {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $squid-ink;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .userName {
        line-height: 16px;
        font-size: 12px;
        color: $fossil;
        white-space: nowrap;
      }
    }

    .access {
      margin-right: 16px;
    }
  }

  .backToSharingButton {
    @include resetButton();

    display: flex;
    align-items: center;
    color: $grid-purple;

    span {
      @include paragraph14SemiBold();

      margin-left: 4px;
    }
  }
}

.skeleton {
  display: flex;
  height: 230px;

  .toggler {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-content: center;
    height: 68px;
    padding: 16px;

    .subTitle {
      height: 16px;
      margin-top: 4px;
      width: 80%;
    }

    .title {
      height: 14px;
      width: 60%;
    }
  }

  .collaborators {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-content: center;
    height: 90px;
    padding: 16px;

    .title {
      height: 20px;
      width: 85px;
    }

    .subTitle {
      height: 34px;
      margin-top: 24px;
      width: 100%;
    }
  }
}
