@import "styles";

.avatarCentipede {
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }
}
