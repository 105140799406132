@import "styles";

.main {
  display: flex;
  flex-flow: column;
  gap: 24px;

  .moreOptionsButton {
    @include resetButton();

    display: flex;
    align-items: center;
    width: fit-content;

    span {
      @include paragraph14SemiBold();

      margin-right: 2px;
      color: $squid-ink;
    }
  }
}
