@import "styles";

.button {
  @include footerButtons();

  margin-top: 24px;
}

.pillsWrapper {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.pills {
  background-color: $mist;
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border-radius: 8px;
  gap: 0 8px;

  .pillWrapper {
    display: flex;
    max-width: 100%;
    width: fit-content;

    &:first-of-type {
      max-width: 72%;
    }
  }

  &.noPills {
    padding: 0;
  }
}

.rights {
  margin-left: 24px;
  min-width: 50px;
  display: flex;
  justify-content: flex-end;
}