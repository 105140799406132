@import "styles";

.wrapper {
  color: $squid-ink;
  padding: 54px 24px !important;
  text-align: center;

  .heading {
    @include display24Bold();
  }

  .body {
    @include paragraph16Regular();

    p {
      padding-top: 12px;
      margin: 0;
    }
  }
}

.loadingWrapper {
  padding: 0 !important;
}
