@import "styles";

.sharingOptions {
  display: flex;
  flex-flow: column;
  gap: 16px;

  .sharingOption {
    display: flex;
    align-items: center;

    span {
      @include paragraph14Regular ();

      margin-right: 4px;
    }

    .icon {
      margin-right: 8px;

      &.enabled svg {
        color: $grid-purple;
      }
    }

    .buttons {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;

      button {
        width: 84px;
        justify-content: center;
      }
    }
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .tooltipWrap {
    display: flex;
    align-items: center;
  }
}
