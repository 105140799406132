@import "styles";

.container {
  .moreOptionHeader {
    display: flex;
    justify-content: space-between;
  }

  .option {
    margin-top: 24px;

    .header {
      @include paragraph14SemiBold();

      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      .icon {
        margin-right: 8px;
      }
    }

    .info {
      @include paragraph14Regular();

      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $ash;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .getEmbedCode {
    @include resetButton();
    @include paragraph14SemiBold();

    color: $grid-purple;
    display: flex;
    align-items: center;

    .icon {
      margin-left: 2px;
    }
  }

  .passwordInput {
    display: flex;
    margin-top: 8px;
    gap: 8px;
    align-items: flex-end;
  }
}
