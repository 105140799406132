@import "styles";

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &:not(.stacked) {
    @include innerGap(8px);
  }

  &.stacked {
    flex-flow: column;

    @include spaceVertically(8px);
  }
}
