@import "styles";

.tag {
  border-radius: 7px;
  color: $white;
  font-weight: 600;
  padding: 0 6px;
  font-size: 10px;
  background: linear-gradient(90deg, $grid-purple 0%, $lilac 100%);
  position: absolute;
  height: 16px;
  display: flex;
  align-items: center;

  &.secondary {
    background: $sweet-rocket;
    color: $grid-purple;
  }

  &.pro {
    background: $grid-gradient;
  }
}
