@import "styles";

// Variables
$loadingHeight: calc(100% - 4px);

// Keyframes
@keyframes load {
  to { transform: translate(-10%, $loadingHeight); }
}

@keyframes come {
  33% { transform: translate(0%, $loadingHeight); }
  100% { transform: translate(0, 0); }
}

@keyframes go {
  0% { transform: translate(0, 0); }
  33% { transform: translate(0, $loadingHeight); }
  100% { transform: translate(100%, $loadingHeight); }
}

@keyframes fadeIn {
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
}

// Styles
.toast {
  position: relative;
  height: 0;
  width: 100%;

  .message {
    @include paragraph14Regular();

    opacity: 0;
    color: $white;

    a {
      color: $white;
    }
  }

  .action {
    @include resetButton();
    @include paragraph14SemiBold();

    color: $white;
    margin-left: 16px;
    text-decoration: underline;
  }

  .outer {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .inner {
    display: flex;
    height: fit-content;
    padding: 16px;
    background: linear-gradient(90deg, $grid-purple, $lupine);
    transform: translate(-100%, $loadingHeight);
    transition: background 300ms;

    &.error {
      background: $pillar-box;
    }

    &.load {
      animation: load 10000ms cubic-bezier(0, 1, 0, 1) forwards;
    }

    &.stay {
      transform: translate(0, 0);

      .message {
        animation: fadeIn 200ms forwards;
      }
    }

    &.come {
      transform: translate(-10%, $loadingHeight);
      animation: come 300ms ease-out;
    }

    &.go {
      animation: go 300ms 200ms ease-in backwards;

      .message {
        animation: fadeOut 200ms;
      }
    }
  }
}
