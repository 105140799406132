@import "styles";

.sharing {
  color: $squid-ink;
  overflow: hidden;

  .sections {
    width: 200%;
    display: flex;
    transition: transform 300ms cubic-bezier(0, 0, 0, 1);

    &.showPrimary {
      transform: translateX(0);
    }

    &.showSecondary {
      transform: translateX(-50%);
    }

    .section {
      flex-basis: 100%;
    }
  }

  .primary, .secondary {
    > * {
      padding: 16px;
    }
  }
}

.sharingToast {
  @media (max-width: $designs-mobile) {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}
