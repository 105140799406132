@import "styles";

.wrapper {
  padding: 24px 16px;
  color: $squid-ink;

  &.modalWrapper {
    padding: 0;
  }

  .heading {
    @include paragraph16SemiBold();

    margin: 0;
  }

  .paragraph {
    @include paragraph14Regular();

    margin: 0;
    margin-bottom: 8px;
  }

  .input {
    margin-bottom: 16px;
    position: relative;
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 12px;
    }
  }
}
